var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_sales_margin_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "label-col": { span: 8 },
            "wrapper-col": { span: 14 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.findData.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.invoiceArDate.label)
                      }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.invoiceArDate.decorator,
                            expression: "formRules.invoiceArDate.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.invoiceArDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.invoiceApDate.label)
                      }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.invoiceApDate.decorator,
                            expression: "formRules.invoiceApDate.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.invoiceApDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.productCategory.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.productCategory.decorator,
                              expression: "formRules.productCategory.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.productCategory.name,
                            placeholder: _vm.$t(
                              _vm.formRules.productCategory.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.loading.productCategory,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchData(value, "productCategory")
                            }
                          }
                        },
                        _vm._l(_vm.dataListProductCategory, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.branchName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branchName.decorator,
                              expression: "formRules.branchName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branchName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branchName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.branchName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchData(value, "branch")
                            }
                          }
                        },
                        _vm._l(_vm.dataListBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.supplierName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.supplierName.decorator,
                              expression: "formRules.supplierName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.supplierName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.supplierName.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.loading.supplier,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchData(value, "supplierName")
                            }
                          }
                        },
                        _vm._l(_vm.dataListSupplier, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.firstName) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.firstName) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.loading.customerName,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchData(value, "customerName")
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.firstName) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.firstName) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "html-type": "button" },
                          on: { click: _vm.clear }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dataSource,
            columns: _vm.columnsTable,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              },
              showSizeChanger: true,
              pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
            },
            size: "small",
            defaultExpandAllRows: "",
            expandRowByClick: "",
            scroll: { x: 2500, y: 552 },
            loading: _vm.loading.find
          },
          scopedSlots: _vm._u([
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "number",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            },
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            },
            {
              key: "expandedRowRender",
              fn: function(record) {
                return [
                  _c(
                    "a-descriptions",
                    { attrs: { size: "small", column: 5 } },
                    [
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_ar_date") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(record.invoiceArDate)) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_ar_amount") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm._f("currency")(record.dpp)) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_invoice_ar_status") } },
                        [
                          _vm._v(
                            " " + _vm._s(record.invoiceArStatus || "-") + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_qty_po") } },
                        [_vm._v(" " + _vm._s(record.supplierQty || "-") + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: _vm.$t("lbl_uom") } },
                        [_vm._v(" " + _vm._s(record.arUom || "-") + " ")]
                      )
                    ],
                    1
                  ),
                  _c("a-divider", { attrs: { orientation: "left" } }, [
                    _vm._v(_vm._s(_vm.$t("lbl_details")))
                  ]),
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": record.detail,
                        columns: _vm.detailColumn,
                        "row-key": function(r, i) {
                          return i
                        },
                        size: "small",
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          },
                          showSizeChanger: true,
                          pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "currency",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("currency")(text)))
                              ])
                            }
                          },
                          {
                            key: "number",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("qty")(text)))
                              ])
                            }
                          },
                          {
                            key: "nullable",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(text || "-"))
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_invoice_ap")))
                      ])
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            { attrs: { slot: "footer", size: "small" }, slot: "footer" },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_dpp_total") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.totalDPP)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_invoice_total") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.totalAP)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_margin_total") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.dataReport.totalMargin)) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.download }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }