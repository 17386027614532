


































































































































































































































import { SearchBuilder } from "@/builder";
import {
  Row,
  useBlob,
  useBranch,
  useContactData,
  useDate,
  useProductCategory,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { IProductCategory } from "@/models/interface/product.interface";
import {
  MarginSalesHeadReportResponseDTO,
  MarginSalesReportResponseDTO,
} from "@/models/interface/ReportMarginSales.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment, { Moment } from "moment";
import { Component, Mixins } from "vue-property-decorator";

type TableRow = Row<MarginSalesReportResponseDTO> & { no: number };
type FormField = {
  branchId: string | null;
  productCategoryId: string | null;
  customerId: string | null;
  supplierId: string | null;
  invoiceArDate: Array<Moment> | null;
  invoiceApDate: Array<Moment> | null;
};

const { toStartDay, toEndDay, toDefaultFormat } = useDate();
const { findById: findBranchById } = useBranch();
const { findById: findContactById } = useContactData();
const { findAll } = useProductCategory();
const { toDownload } = useBlob();

@Component
export default class Margin extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  moment = moment;
  form!: WrappedFormUtils;
  dataSource: Array<TableRow> = [];
  dataListCustomer: ContactData[] = [];
  dataListSupplier: ContactData[] = [];
  dataListBranch: DataWarehouseBranch[] = [];
  dataListProductCategory: IProductCategory[] = [];
  dataReport: MarginSalesHeadReportResponseDTO = {
    data: [] as Array<MarginSalesReportResponseDTO>,
    totalDPP: 0,
    totalAP: 0,
    totalMargin: 0,
  };
  loading = {
    find: false,
    customerName: false,
    branchName: false,
    supplier: false,
    productCategory: false,
    download: false,
  };
  detailColumn = [
    {
      title: this.$t("lbl_status"),
      dataIndex: "invoiceApStatus",
      key: "invoiceApStatus",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qtySale",
      key: "qtySale",
      width: 100,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "apUom",
      key: "apUom",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      width: 100,
      scopedSlots: { customRender: "currency" },
    },
  ];
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 75,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "partNumber",
      key: "partNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "partName",
      key: "partName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_category"),
      dataIndex: "partCategory",
      key: "partCategory",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      key: "brand",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      key: "capacity",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      key: "specification",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
      key: "customerCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_ar_no"),
      dataIndex: "invoiceArNumber",
      key: "invoiceArNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_margin"),
      dataIndex: "margin",
      key: "margin",
      scopedSlots: { customRender: "currency" },
    },
  ];
  formRules = {
    branchName: {
      label: "lbl_branch_name",
      name: "lbl_branch_name",
      placeholder: "lbl_branch_name_placeholder",
      decorator: ["branchId"],
    },
    productCategory: {
      label: "lbl_part_category",
      name: "lbl_part_category",
      placeholder: "lbl_part_category",
      decorator: ["productCategoryId"],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "lbl_customer_name",
      placeholder: "lbl_customer_name_placeholder",
      decorator: ["customerId"],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "lbl_supplier_name",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: ["supplierId"],
    },
    invoiceArDate: {
      label: "lbl_invoice_ar_date",
      name: "invoiceArDate",
      decorator: ["invoiceArDate"],
    },
    invoiceApDate: {
      label: "lbl_invoice_ap_date",
      name: "invoiceApDate",
      decorator: ["invoiceApDate"],
    },
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "margin" });
  }

  mounted() {
    this.getCustomer("");
    this.getSupplier("");
    this.getListBranch("");
    this.getProductCategory("");
  }

  async download(): Promise<void> {
    try {
      this.loading.download = true;
      const formValues: FormField = this.form.getFieldsValue() as FormField;
      const params = new RequestQueryParams();
      params.params = await this.buildParams(formValues);
      params.search = this.buildSearch(formValues);
      const response = await salesOrderServices.downloadReportUnitMarginProduct(
        params
      );
      toDownload(response, "margin_sales_report.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  clear() {
    this.form.resetFields();
  }

  getProductCategory(value: string) {
    this.loading.productCategory = true;
    const params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    if (value) params.search = `name~*${value}*`;
    productService
      .listProductCategory(params)
      .then(res => {
        this.dataListProductCategory = res.data;
      })
      .finally(() => (this.loading.productCategory = false));
  }

  getCustomer(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `customer~true_AND_active~true`,
    };
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.customerName = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loading.customerName = false));
  }

  getSupplier(value: string): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true`,
    };
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.supplier = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListSupplier = data.data;
      })
      .finally(() => (this.loading.supplier = false));
  }

  getListBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_address~*${valueSearch}*`;
    this.loading.branchName = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => (this.dataListBranch = response.data))
      .finally(() => (this.loading.branchName = false));
  }

  searchData(
    value: string,
    type: "customerName" | "productCategory" | "supplierName" | "branch"
  ) {
    switch (type) {
      case "customerName":
        this.getCustomer(value);
        break;
      case "productCategory":
        this.getProductCategory(value);
        break;
      case "supplierName":
        this.getSupplier(value);
        break;
      case "branch":
        this.getListBranch(value);
        break;
      default:
        break;
    }
  }

  async buildParams({
    branchId,
    customerId,
    invoiceArDate,
    productCategoryId,
    supplierId,
    invoiceApDate,
  }: FormField): Promise<string> {
    const company = this.$store.state.authStore.authData.companyName || "ALL";

    let branchName = "ALL";
    if (branchId) {
      const { name } = await findBranchById(branchId);
      branchName = name;
    }

    let customerName = "ALL";
    if (customerId) {
      const { firstName } = await findContactById(customerId);
      customerName = firstName;
    }

    let supplierName = "ALL";
    if (supplierId) {
      const { firstName } = await findContactById(supplierId);
      supplierName = firstName;
    }

    let partCategory = "ALL";
    if (productCategoryId) {
      const res = await findAll({ search: "secureId~" + productCategoryId });
      const [item] = res.data;
      partCategory = item.name || "ALL";
    }

    let periodArFrom = "ALL";
    let periodArTo = "ALL";
    if (invoiceArDate && invoiceArDate.length) {
      const [start, end] = invoiceArDate;
      periodArFrom = toDefaultFormat(start);
      periodArTo = toDefaultFormat(end);
    }

    let periodApFrom = "ALL";
    let periodApTo = "ALL";
    if (invoiceApDate && invoiceApDate.length) {
      const [start, end] = invoiceApDate;
      periodApFrom = toDefaultFormat(start);
      periodApTo = toDefaultFormat(end);
    }

    const q: Array<string> = [
      company,
      branchName,
      customerName,
      supplierName,
      partCategory,
      periodArFrom,
      periodArTo,
      periodApFrom,
      periodApTo,
    ];

    return q.join(",");
  }

  buildSearch({
    branchId,
    customerId,
    invoiceArDate,
    productCategoryId,
    supplierId,
    invoiceApDate,
  }: FormField): string {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (customerId) {
      q.push(builder.push(["customerId", customerId]).build());
    }

    if (invoiceArDate && invoiceArDate.length) {
      const [start, end] = invoiceArDate;
      q.push(
        builder
          .push(["invoiceArDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceArDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (invoiceApDate && invoiceApDate.length) {
      const [start, end] = invoiceApDate;
      q.push(
        builder
          .push(["invoiceApDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceApDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (productCategoryId) {
      q.push(builder.push(["partCategoryId", productCategoryId]).build());
    }

    if (supplierId) {
      q.push(builder.push(["supplierId", supplierId]).build());
    }

    return q.join(builder.AND);
  }

  findData(): void {
    this.form.validateFields((err, res: FormField) => {
      if (err) {
        this.showNotifError("notif_validation_error");
        return;
      }

      const params = new RequestQueryParams();
      params.search = this.buildSearch(res);

      this.getData(params);
    });
  }

  getData(params: RequestQueryParamsModel): void {
    this.loading.find = true;
    salesOrderServices
      .getListReportUnitMarginProduct(params)
      .then(response => {
        this.dataReport = response;
        this.dataSource = this.dataReport.data.map<TableRow>((item, i) => ({
          ...item,
          key: i,
          no: i + 1,
        }));
      })
      .finally(() => (this.loading.find = false));
  }
}
